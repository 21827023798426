<template>
  <div class="row">
    <loading-notification :show="loading" />
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12 md4">
      <va-card :title="$t('logs.view')">
        <actions
          slot="actions"
          crud-links="logs"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <text-list :title="$t('tables.headings.action')">
          <va-badge :color="getStatusColor(log.executed)">
            {{ $t(getStatusLabel(log.executed)) }}
          </va-badge>
        </text-list>
        <text-list :title="$t('tables.headings.table')">
          {{ log.table_name }}
        </text-list>
        <text-list
          v-if="log.executed !== 'D'"
          :condition="currentUser.can(log.table_name, 'view')"
          :title="$t('tables.headings.affected_entity')"
          :label="$t('layout.click_to_open')"
          :to="{ name: getRoute(log.table_name), params: {id: log.reference_id}}"
        />
        <text-list
          v-if="log.user"
          :condition="currentUser.can('Users', 'view')"
          :title="$t('tables.headings.created_by')"
          :label="log.user.name"
          :to="{ name: 'usersView', params: {id: log.user.id}}"
        />
        <text-list :title="$t('tables.headings.created_at')">
          {{ dateLabel(log.created_at) }}
        </text-list>
      </va-card>
    </div>
    <div
      v-if="log.reference"
      class="flex xs12 md8"
    >
      <va-card :title="$t('logs.data')">
        <div class="code-pre">
          <div slot="content">
            <pre>
              <code class="json">{{ formattedJson }}</code>
            </pre>
          </div>
        </div>
      </va-card>
    </div>
    <div
      class="flex xs12"
      :class="{'md8': !log.reference}"
    >
      <va-card :title="$t('logs.entity_changes')">
        <remote-table
          class="table-hover table-striped"
          :columns="tableFields"
          :data="tableData"
          :loading="loading"
          :search="searchOptions"
          :pagination="pagination"
          :top-options="[]"
          :action-options="['view2', 'delete']"
          crud-links="logs"
          @update-data="updateChangesData"
          @search-data="searchByName"
          @delete-item="tryDelete"
          @view_two="tryView"
        >
          <template v-slot:row-status="{ props }">
            <va-badge :color="getStatusColor(props.rowData.executed)">
              {{ $t(getStatusLabel(props.rowData.executed)) }}
            </va-badge>
          </template>
        </remote-table>
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'logs-view',
  components: {
    RemoteTable, Actions, TextList,
  },
  data () {
    return {
      error: false,
      log: {},
      loading: false,
      actions: ['index', 'delete'],
      tableData: [],
      pagination: {},
      searchQuery: '',
      btn: '',
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
        // externalQuery: searchQuery
      },
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '', // 'asc' or 'desc'
        },

        page: 1, // what page I want to show
        perPage: 50, // how many items I'm showing per page
      },
    }
  },
  created () {
    this.updateData()
  },
  computed: {
    ...mapGetters(['currentUser']),
    formattedJson () {
      const json = JSON.parse(this.log.reference) || {}
      return JSON.stringify(json, null, 4)
    },
    tableFields () {
      return [
        {
          name: '__slot:status',
          title: this.$t('tables.headings.action'),
          sortField: 'executed',
        },
        {
          name: 'table_name',
          title: this.$t('tables.headings.table'),
          sortField: 'table_name',
        },
        {
          name: 'user_email',
          title: this.$t('tables.headings.user'),
          sortField: 'user_email',
        },
        {
          name: 'created_at',
          title: this.$t('tables.headings.created_at'),
          sortField: 'created_at',
          callback: this.dateLabel,
        },
        {
          name: '__slot:actions',
          visible: this.tableData.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  methods: {
    getStatusColor (status) {
      let color = 'success'
      switch (status) {
        case 'A': color = 'success'; break
        case 'U': color = 'primary'; break
        case 'D': color = 'danger'; break
      }
      return color
    },
    getStatusLabel (status) {
      let label = 'logs.actions.add'
      switch (status) {
        case 'A': label = 'logs.actions.add'; break
        case 'U': label = 'logs.actions.update'; break
        case 'D': label = 'logs.actions.delete'; break
      }
      return label
    },
    getRoute (controller) {
      let label = 'dashboard'
      switch (controller) {
        case 'AssignmentsJfhp': label = 'assignmentsEdit'; break
        case 'Countries': label = 'countriesView'; break
        case 'CountryManagers': label = 'countriesView'; break
        case 'CountryReports': label = 'countryReportsView'; break
        case 'DcpiTrainings': label = 'trainingsView'; break
        case 'DistrictsManagers': label = 'districtsView'; break
        case 'DistrictsPlans': label = 'districtPlansView'; break
        case 'Districts': label = 'districtsView'; break
        case 'EvangelisticMethods': label = 'evangelisticMethodsView'; break
        case 'Goals': label = 'goalsView'; break
        case 'Groups': label = 'groupsView'; break
        case 'Mti': label = 'mtiView'; break
        case 'Parameters': label = 'parametersView'; break
        case 'PrayerRequests': label = 'prayersView'; break
        case 'PreachingPoints': label = 'preachingsView'; break
        case 'Presentations': label = 'reportsView'; break
        case 'Proyections': label = 'reportsView'; break
        case 'RegionManagers': label = 'regionsView'; break
        case 'RegionModules': label = 'regionsView'; break
        case 'RegionReports': label = 'regionReportsView'; break
        case 'Regions': label = 'regionsView'; break
        case 'Teams': label = 'teamsView'; break
        case 'TeamsYearPlans': label = 'teamsView'; break
        case 'TrainingTypes': label = 'trainingTypesView'; break
        case 'Users': label = 'usersView'; break
      }
      return label
    },
    dateLabel (date) {
      if (!date) return ''
      return this.$date.format(date, 'dd/MM/yyyy HH:mm')
    },
    routeBuilder (id) {
      return `logs/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const logId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(logId))
      } catch (err) {
        // console.log('Error fetching log data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.log = u.data.data
      this.updateChangesData()
    },
    fixData (data) {
      return data
    },
    apiUrl (params) {
      let route = 'logs'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        route += '&sort=' + params.sort.field
        route += '&direction=' + params.sort.type
      }
      route += '&table=' + this.log.table_name
      if (this.log.reference_id) {
        route += '&reference=' + this.log.reference_id
      }

      return route
    },
    async updateChangesData (params) {
      params = params || this.serverParams
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async searchByName (name) {
      if (name === '') return this.updateChangesData()

      let response = false
      try {
        response = await this.$http.get('logs?q=' + name)
      } catch (err) {
        // console.log('Error searching country', err)
        this.loading = false
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete('logs/' + item.id)
      } catch (err) {
        // console.log('Error deleting theme', err)
        this.loading = false
        return
      }
      this.updateData()
    },

    async tryView (item) {
      this.$router.push('/admin/configuration/logs/view/' + item.id)
      this.updateData()
    },

  },
}
</script>
